import Behavior from 'flamingo-carotene-behavior'
import * as behaviorModules0 from './modules/cockpitMailSentInfo.js'; import * as behaviorModules1 from './modules/navigationDropdownOnHover.js'; let behaviorModules = [behaviorModules0, behaviorModules1]

import BccSharedCockpitFrontend from 'bcc-shared-cockpit'
behaviorModules = BccSharedCockpitFrontend.addBehaviors(behaviorModules)

// Init Behaviors
const behavior = new Behavior(behaviorModules)
behavior.setDebug(false)
export default behavior
